import {gsap, Power3} from 'gsap/all';
import jbRotateMenu from '@/_page/jbRotateMenu';

export default class jbHeader {
  constructor() {
    if (!document.getElementById('g-icon-menu')) return;
    this.isOpen = false;
    this.isAnimation = false;

    const pathNames = [
      'concept',
      'news',
      'catalogue',
      'movie',
      'shoplist',
      'advisorystaff',
      'special',
    ];

    this.btnMenu = document.getElementById('g-icon-menu');
    this.btnMenu.addEventListener('click', this.toggleMenu.bind(this));
    this.domMenu = document.getElementById('jb-modal-menu');

    // パス確認
    pathNames.forEach((name) => {
      const reg = new RegExp(`^/${name}/`);
      if (location.pathname.match(reg)) {
        gJB.rootHTML.classList.add(`is-page-${name}`);
      }
    });

    if (location.pathname.split('/').length < 3) {
      gJB.rootHTML.classList.add(`is-page-top`);
    }

    this.setting();
  }

  start() {
    
  }

  setting() {
    // ランダムイメージ
    this.maxImg = this.domMenu.querySelectorAll('.jb-modal-menu__img li').length;

    // マウスホバー
    this.rotateMenus = [];
    const menuList = this.domMenu.querySelectorAll('.g-rotateMenu');
    menuList.forEach((menu, i) => {
      this.rotateMenus.push(new jbRotateMenu(menu, i));
    });

    // リサイズ
    const myObserver = new ResizeObserver(this.resize.bind(this));
    myObserver.observe(this.domMenu);
  }

  resize(entries, observer) {
    const trgDom = entries[0].target;
    const wrapH = trgDom.querySelector('.jb-modal-menu__wrap').getBoundingClientRect().height;
    const winH = window.innerHeight;
    if (wrapH > winH) {
      trgDom.classList.add('jb-modal-menu--scroll');
    } else {
      trgDom.classList.remove('jb-modal-menu--scroll');
    }

    // スクロール位置戻す
    this.domMenu.scrollTop = 0;
  }

  toggleMenu() {
    if (this.isOpen) {
      // 閉じる
      this.closeModalMenu();
    } else {
      // 開く
      this.openModalMenu();
    }
  }

  openModalMenu() {
    if (this.isOpen) return;
    if (this.isAnimation) return;
    this.isOpen = true;
    this.isAnimation = true;

    // ランダム画像 表示する
    this.showImg = Math.floor( Math.random() * this.maxImg) + 1;
    this.domMenu.classList.add(`is-show-img${this.showImg}`);
    
    // 表示アニメーション
    gsap.killTweensOf(this.domMenu);
    gJB.rootHTML.classList.add('jb-modal-menu--show', 'jb-modal-menu--open');

    const tl = gsap.timeline({
      onComplete: this.openModalMenuComp.bind(this)
    });

    tl.fromTo(this.domMenu, {
      autoAlpha: 1,
      clipPath: 'inset(0 100% 0 0)',
    }, {
      duration: 0.35,
      clipPath: 'inset(0 50% 0 0)',
      ease: Power3.easeIn,
    })
    .to(this.domMenu, {
      duration: 0.4,
      clipPath: 'inset(0 0% 0 0)',
      ease: Power3.easeOut,
    })
    .fromTo(this.domMenu.querySelector('.jb-modal-menu__img > ul'), {
      duration: 0.5,
      opacity: 0,
    }, {
      opacity: 1,
      ease: Power3.easeInOut,
    }, 0.4);

    this.rotateMenus.forEach((menu, i) => {
      // const delay = 0.3 + ((i % 2) * 0.2);
      const delay = 0.3;
      gsap.delayedCall(delay, () => {
        menu.playOnce();
      });
    });
  }

  openModalMenuComp() {
    this.isAnimation = false;
  }

  closeModalMenu() {
    if (this.isAnimation) return;
    if (!this.isOpen) return;
    this.isAnimation = true;

    // 非表示アニメーション
    gsap.killTweensOf(this.domMenu);
    gJB.rootHTML.classList.remove('jb-modal-menu--open');

    const tl = gsap.timeline({
      onComplete: this.closeModalMenuComp.bind(this)
    });

    tl.to(this.domMenu, {
      duration: 0.7,
      autoAlpha: 0,
      ease: Power3.easeOut,
    });
  }

  closeModalMenuComp() {
    // ランダム画像 非表示
    this.domMenu.classList.remove(`is-show-img${this.showImg}`);

    // 表示アニメーション リセット
    gJB.rootHTML.classList.remove('jb-modal-menu--show');
    this.isOpen = false;

    this.isAnimation = false;

    this.rotateMenus.forEach((menu) => {
      menu.reset();
    });
  }
}
