import 'lazysizes';
import objectFitImages from 'object-fit-images';
objectFitImages();

import EventEmitter from 'eventemitter3';
window.EventEmitter = new EventEmitter();

//////////////////////////////
// グローバル変数
//////////////////////////////
window.gJB = [];
window.gJB.glLoaded = false;
window.gJB.rootHTML = document.documentElement;
window.gJB.lang = window.gJB.rootHTML.lang;
window.gJB.mods = [];
window.gJB.isDevice = "";
window.gJB.isAndroid = false;
window.gJB.isEnableTouch = false;
window.gJB.siteUrl = location.protocol + "//" + location.hostname;
if (location.port != 80) {
  window.gJB.siteUrl += ":" + location.port;
}

const ua = navigator.userAgent.toLowerCase();
// if (/iphone|ipad|macintosh|ipod/.test(ua) && 'ontouchend' in document) {
if (/iphone|ipod/.test(ua) && 'ontouchend' in document) {
  gJB.rootHTML.classList.add('jb-mobile');
  gJB.isDevice = 'mobile';
  gJB.isAndroid = false;
} else if (/ipad|macintosh/.test(ua) && 'ontouchend' in document) {
  gJB.rootHTML.classList.add('jb-mobile');
  gJB.isDevice = 'tablet';
  gJB.isAndroid = false;
} else if (/android/.test(ua) && 'ontouchend' in document) {
  gJB.rootHTML.classList.add('jb-mobile');
  gJB.isDevice = 'mobile';
  gJB.isAndroid = true;
} else {
  gJB.rootHTML.classList.add('jb-pc');
  gJB.isDevice = 'pc';
  gJB.isAndroid = false;
}

if (window.ontouchstart !== undefined && 0 < navigator.maxTouchPoints) {
  gJB.isEnableTouch = true;
}

//history.scrollRestoration = 'manual';
