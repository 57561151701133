'use strict';
import '@/_constant/global';
import JbHeader from '@/_page/JbHeader';
import jbModalVideo from '@/_page/jbModalVideo';

document.addEventListener('DOMContentLoaded', () => {
  gJB.mods['JbHeader'] = new JbHeader();
  gJB.mods['jbModalVideo'] = new jbModalVideo();
},false);

window.addEventListener('load', () => {
  gJB.mods['JbHeader'].start();
  gJB.mods['jbModalVideo'].start();
},false);

window.addEventListener('unload', () => {}, false);