import {gsap, Power3} from 'gsap/all';

export default class jbRotateMenu {
  constructor(menu, id) {
    this.id = id;
    this.menu = menu;
    this.texts = menu.querySelectorAll('.is-text')
    this.isHover = false;
    gsap.set(this.texts[1].querySelectorAll('span'), {y: '100%'});

    menu.addEventListener('mouseenter', this.mouseEnter.bind(this));
  }

  reset() {
    gsap.set(this.texts[0].querySelectorAll('span'), {y: '0%'});
    gsap.set(this.texts[1].querySelectorAll('span'), {y: '100%'});
  }

  playOnce() {
    this.isHover = true;
    // gsap.set(this.texts[0].querySelectorAll('span'), {y: '0%'});

    const tl = gsap.timeline({
      onComplete: this.rotateOnceComp.bind(this)
    });
    tl.fromTo(this.texts[1].querySelectorAll('span'), {
      y: '100%',
    }, {
      duration: 0.4,
      y: '0%',
      ease: Power3.easeOut,
      stagger: 0.05,
    });
  }

  mouseEnter(e) {
    if (this.isHover) return;
    this.isHover = true;
    this.rotateOnce();
  }

  rotateOnce() {
    const tl = gsap.timeline({
      onComplete: this.rotateOnceComp.bind(this)
    });
    tl.fromTo(this.texts[1].querySelectorAll('span'), {
      y: '0%',
    }, {
      duration: 0.4,
      y: '-100%',
      ease: Power3.easeOut,
      stagger: 0.05,
    })
    .fromTo(this.texts[0].querySelectorAll('span'), {
      y: '0%',
    }, {
      duration: 0.4,
      y: '-100%',
      ease: Power3.easeOut,
      stagger: 0.05,
    }, 0.1);
  }

  rotateOnceComp() {
    this.isHover = false;
  }
}
