import {gsap, Power3} from 'gsap/all';

export default class jbModalVideo {
  constructor() {
    if (!document.querySelector('.js-modalVideo')) return;
    
    this.isOpen = false;

    this.modals = document.querySelectorAll('.js-modalVideo');
    this.modals.forEach((el, i) => {
      const videoSrc = el.getAttribute('data-src');
      el.addEventListener('click', this.openModalVideo.bind(this, {src: videoSrc}));
    });

    this.domModal = document.getElementById('jb-modal-video');
    this.domModalbg = this.domModal.querySelector('.jb-modal-video__bg');
    this.domModalBtn = this.domModal.querySelector('.g-btnClose');
    this.domModalbg.addEventListener('click', this.closeModalVideo.bind(this));
    this.domModalBtn.addEventListener('click', this.closeModalVideo.bind(this));
  }

  start() {
    
  }

  openModalVideo(arg, e) {
    e.preventDefault();
    if (this.isOpen) return;
    this.isOpen = true;

    gsap.killTweensOf([this.domModal]);

    this.domModal.querySelector('.jb-modal-video__wrap').innerHTML = `<iframe width="560" height="315" src="${arg.src}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
    this.domModal.classList.add('jb-modal-video--open');

    const tl = gsap.timeline();
    tl.fromTo(this.domModal, {
      autoAlpha: 1,
      clipPath: 'inset(0 100% 0 0)',
    }, {
      duration: 0.35,
      clipPath: 'inset(0 50% 0 0)',
      ease: Power3.easeIn,
    })
    .to(this.domModal, {
      duration: 0.4,
      clipPath: 'inset(0 0% 0 0)',
      ease: Power3.easeOut,
    });
  }

  closeModalVideo(e) {
    e.preventDefault();
    if (!this.isOpen) return;
    this.isOpen = false;

    this.domModal.querySelector('iframe').remove();
    gsap.killTweensOf([this.domModal]);

    const tl = gsap.timeline({
      onComplete: this.closeModalVideoComp.bind(this)
    });

    tl.to(this.domModal, {
      duration: 0.7,
      autoAlpha: 0,
      ease: Power3.easeOut,
    });
  }

  closeModalVideoComp() {
    this.domModal.classList.remove('jb-modal-video--open');
  }
}
